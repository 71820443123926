import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function SeoPage(props) {
    return (
        <div className="container draft-view">
           <h1>SEO Services</h1>
           <p className="text-muted">Most buyers begin their session by searching for something they need. Getting relevant organic traffic is the main goal for your site because it is a good source of customers and revenue.</p>
           <p className="text-muted">Extensive experience allows us to confidently declare that we can get your site towards the top of search engine rankings and make it popular among the target audience, no matter your industry, domain age, location, or type of site.</p>
           <p className="text-muted">Every day, we study and closely monitor the changes in search engines to improve ranking. That’s why SEO site optimization from our agency is high-quality and effective. We use only “white hat” methods that yield a positive and long-term result!</p>
           <p className="text-muted">SEO specialists from Wescale make your business more visible in searches with our custom SEO services.</p>
           <p className="text-muted"><br /></p>
           <h2>How SEO helps your business</h2>
           <p className="text-muted">Search engine optimization (SEO) involves small modifications to your website. When combined with other optimizations, this can have a noticeable impact on your site’s performance in organic search results.</p>
           <p className="text-muted"><br /></p>
           <h4>Why do websites need search engine optimization (SEO)</h4>
           <p className="text-muted">If two websites are selling the same product or service, the search engine optimized website will have a larger number of customers and sales, because the optimized site has a higher position in search results and gives visitors a better user experience.</p>
           <p className="text-muted">In reality, there are always more than two sites competing in the organic search. Your website can compete with several, dozens, or even thousands of sites.</p>
           <p className="text-muted">So if you want to sell or promote your business on the web, you should use SEO.</p>
           <p className="text-muted">Our SEO specialists will choose SEO tactics that fit your requirements and business goals.</p>
           <p className="text-muted"><br /></p>
           <h2>Your benefits</h2>
        <p className="text-muted"></p>
           <h5>Relevant traffic</h5>
           <p className="text-muted">Large amounts of traffic can actually drop the position of your site if that traffic is irrelevant. What you need are more customers and fewer visitors who do not turn into customers. Our SEO specialists work closely with marketers to choose solutions that attract a relevant audience.</p>
           <p className="text-muted"><br /></p>
           <h5>Long-term results</h5>
           <p className="text-muted">While a fast result is undeniably good for your business, you also need long-term results. Our specialists combine different approaches that work regardless of changes in search algorithms and competitor actions.</p>
           <p className="text-muted"><br /></p>
           <h5>Individual attention to your business</h5>
           <p className="text-muted">It is often tempting to use trendy tactics. From our experience, it is better to create an individual SEO strategy for your business. It is also a more cost-effective approach.</p>
           <p className="text-muted"><br /></p>
           <h2>Why is it a good idea to order our SEO service?</h2>
           <p className="text-muted">We can help you in a time of crisis when your site’s traffic suddenly drops or is penalized by a search engine.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">For long-term SEO results, we use a thorough approach that requires a little more time and effort.&nbsp;</span><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">We work in the field of Internet marketing and use only working techniques to raise a resource in search results.</span></p>
           <p className="text-muted"><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted"><br /></span></p>
           <h2>SEO Strategy Development and Implementation</h2>
           <p className="text-muted"><font face="Poppins, Helvetica, sans-serif">We create the most appropriate SEO strategy for your business and document it. Then we create a detailed plan.</font></p>
           <p className="text-muted"><font face="Poppins, Helvetica, sans-serif">Please note that this plan may at some point undergo changes because final results depend on more than 200 ranking factors (according to Google) and it is difficult to do it perfectly the first time.</font></p>
           <p className="text-muted"><font face="Poppins, Helvetica, sans-serif">We can implement all of our recommendations from tech to SEO content.</font></p>

        </div>

    );
}
